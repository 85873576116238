'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var Flickity = require('flickity');

// not beautiful, but it works

var Slider = function () {
	function Slider(sliderDomElement) {
		_classCallCheck(this, Slider);

		this.sliderDomElement = sliderDomElement;

		// vars
		this.flkty;
		this.dots;
		this.playPauseButton;

		/**
   * The status of the slideshow
   * @type boolean
   */
		this.isPlaying;

		this.createSlideshow();
		this.createPager();
		this.start();
		this.actions();
	}

	_createClass(Slider, [{
		key: 'actions',
		value: function actions() {
			this.playPauseButton.addEventListener('click', this.onClick.bind(this));
			this.playPauseButton.addEventListener('touchstart', this.onClick.bind(this));
		}

		// onClick event for the button

	}, {
		key: 'onClick',
		value: function onClick(event) {
			event.preventDefault();
			this.toggle();
		}
	}, {
		key: 'createSlideshow',
		value: function createSlideshow() {
			// element argument can be a selector string
			//   for an individual element
			this.flkty = new Flickity(this.sliderDomElement, {
				// options
				cellAlign: 'left',
				cellSelector: '.BannerSlider-cell',
				contain: true,
				prevNextButtons: false,
				setGallerySize: false,
				lazyLoad: 2,
				wrapAround: true,
				// animation
				autoPlay: 6000,
				selectedAttraction: 0.01,
				friction: 0.15
			});
		}
	}, {
		key: 'createPager',
		value: function createPager() {
			// Selected flickity dots container
			this.dots = document.querySelector('.flickity-page-dots');

			// Add another list Element for the play/pause button
			this.playPauseButton = document.createElement('li');
			this.playPauseButton.classList.add('BannerSlider-button');

			// Append it to the ol.flickity-page-dots children
			this.dots.appendChild(this.playPauseButton);
		}

		/**
   * Starts autoPlay of the slider
   */

	}, {
		key: 'start',
		value: function start() {
			this.flkty.playPlayer();
			this.isPlaying = true;
			this.playPauseButton.classList.remove('is-stopped');
		}

		/**
   * Stops autoPlay of the slider
   */

	}, {
		key: 'stop',
		value: function stop() {
			this.flkty.stopPlayer();
			this.isPlaying = false;
			this.playPauseButton.classList.add('is-stopped');
		}
	}, {
		key: 'toggle',
		value: function toggle() {
			if (this.isPlaying) {
				this.stop();
			} else {
				this.start();
			}
		}
	}]);

	return Slider;
}();

module.exports = Slider;
