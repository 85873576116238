'use strict';

var Main = require('./Main');

var main = new Main();
main.start();

if (window.console) {
	console.log('Site by KOPFWELT http://kopfwelt.com');
}
