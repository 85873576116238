'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var $ = require('jquery');
var EventEmitter = require('wolfy87-eventemitter');

var Burger = function (_EventEmitter) {
	_inherits(Burger, _EventEmitter);

	function Burger() {
		_classCallCheck(this, Burger);

		var _this = _possibleConstructorReturn(this, (Burger.__proto__ || Object.getPrototypeOf(Burger)).call(this));

		$('.BurgerWrap').on('click', function (event) {
			return _this.open(event);
		});
		return _this;
	}

	_createClass(Burger, [{
		key: 'keyDown',
		value: function keyDown(event) {
			if (event.which === 27) {
				this.close(event);
			}
		}
	}, {
		key: 'open',
		value: function open(event) {
			var _this2 = this;

			event.preventDefault();
			this.toggle(event);
			$(document).on('keydown.navburger', function (event) {
				return _this2.keyDown(event);
			});
		}
	}, {
		key: 'toggle',
		value: function toggle(event) {
			var target = event.currentTarget.getAttribute('href');

			$(event.currentTarget).toggleClass('is-active');
			$('.SiteHeader').toggleClass('aside-open');
			$('.SiteAside').toggleClass('is-active');
			$('.NavAside').toggleClass('aside-open');
			$('body').toggleClass('u-noScroll');

			this.emitEvent('didToggle', [target]);
		}
	}, {
		key: 'close',
		value: function close() {
			$('.BurgerWrap').removeClass('is-active');
			$('.SiteAside').removeClass('is-active');
			$('.SiteHeader').removeClass('aside-open');
			$('.NavAside').removeClass('aside-open');
			$('body').removeClass('no-scroll');

			$(document).off('.navburger');
		}
	}]);

	return Burger;
}(EventEmitter);

module.exports = Burger;
